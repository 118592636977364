import { SoftwrkApi } from "configs/StoreQueryConfig";
import { StoreQueryTagEnum } from "constants/StoreConstants";
import { UserTypeEnum } from "features/user/UserConstants";
import { providesTags, invalidatesTags } from "utils/QueryUtils";

export const USERS_API_URL = "/api/v1/users";

export const UserApi = SoftwrkApi.injectEndpoints({
  endpoints: (builder) => ({
    login: builder.mutation({
      // query: (config) => ({
      //   url: `${USERS_API_URL}/signin`,
      //   method: "post",
      //   ...config,
      // }),
      queryFn: async (config, _, __, baseQuery) => {
        const queryResult = await baseQuery({
          url: `${USERS_API_URL}/signin`,
          method: "POST",
          ...config,
        });

        if (queryResult.error) {
          return queryResult;
        }

        if (queryResult.data?.data.user?.type !== UserTypeEnum.ADMIN) {
          return {
            error: {
              data: {
                status: 400,
                message: "Invalid username or password",
                errors: [],
                name: "ValidationException",
                path: "/api/v1/users/signin",
              },
            },
          };
        }

        return queryResult;
      },
    }),
    getUsers: builder.query({
      query: (config) => ({ url: `${USERS_API_URL}`, ...config }),
      providesTags: (data) => providesTags(data?.data, StoreQueryTagEnum.USER),
    }),
    getUser: builder.query({
      query: ({ path, ...config }) => ({
        url: `${USERS_API_URL}/${path.id}`,
        ...config,
      }),
      providesTags: (data) =>
        providesTags([data?.data], StoreQueryTagEnum.USER),
    }),
    updateUser: builder.mutation({
      query: ({ path, ...config }) => ({
        url: `${USERS_API_URL}/${path.id}`,
        method: "put",
        ...config,
      }),
      invalidatesTags: (data, error, { path }) =>
        error ? [] : invalidatesTags(StoreQueryTagEnum.USER, path?.id),
    }),
    deleteUser: builder.mutation({
      query: ({ path, ...config }) => ({
        url: `${USERS_API_URL}/${path.id}`,
        method: "delete",
        ...config,
      }),
      invalidatesTags: (data, error, { path }) =>
        error ? [] : invalidatesTags(StoreQueryTagEnum.USER, path?.id),
    }),
    getUsersTypes: builder.query({
      query: (config) => ({
        url: `${USERS_API_URL}/types`,
        ...config,
      }),
    }),
    getUsersStatuses: builder.query({
      query: (config) => ({
        url: `${USERS_API_URL}/statuses`,
        ...config,
      }),
    }),
    updateUserStatus: builder.mutation({
      query: ({ path, ...config }) => ({
        url: `${USERS_API_URL}/${path.id}/status`,
        method: "put",
        ...config,
      }),
      invalidatesTags: (data, error, { path }) =>
        error ? [] : invalidatesTags(StoreQueryTagEnum.USER, path?.id),
    }),
    updateUserVerifiedDetails: builder.mutation({
      query: ({ path, ...config }) => ({
        url: `${USERS_API_URL}/verified-details`,
        method: "PUT",
        ...config,
      }),
      invalidatesTags: (_, error, { path }) =>
        invalidatesTags(StoreQueryTagEnum.USER, undefined, error),
    }),
    approveUserVerifiedDetails: builder.mutation({
      query: ({ path, ...config }) => ({
        url: `${USERS_API_URL}/verified-details/approve`,
        method: "PUT",
        ...config,
      }),
      invalidatesTags: (_, error, { path }) =>
        invalidatesTags(StoreQueryTagEnum.USER, undefined, error),
    }),
  }),
});

export default UserApi;

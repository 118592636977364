import axios from "axios";

export const SoftworkHttp = axios.create({
  baseURL: process.env.REACT_APP_SOFTWRK_API,
});

export const CloudinaryHttp = axios.create({
  baseURL: process.env.REACT_APP_CLOUDINARY_API_URL,
});

export default SoftworkHttp;

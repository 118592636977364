export const UserTypeEnum = {
  SUPER_ADMIN: "SUPER_ADMIN",
  ADMIN: "ADMIN",
  CLIENT: "CLIENT",
  FREELANCER: "FREELANCER",
};

export const UserStatusEnum = {
  UNVERIFIED: "UNVERIFIED",
  UPDATE_REQUIRED: "UPDATE_REQUIRED",
  AWAITING_APPROVAL: "AWAITING_APPROVAL",
  ACTIVE: "ACTIVE",
  IN_ACTIVE: "IN_ACTIVE",
  DEACTIVATED: "DEACTIVATED",
  REJECTED: "REJECTED",
};

export function providesTags(resultsWithIds, tagType, options = {}) {
  const { selectId = ({ id }) => id } = options;
  const listTag = { type: tagType, id: "LIST" };
  const result = resultsWithIds
    ? [
        listTag,
        ...resultsWithIds.map((result) => ({
          type: tagType,
          ...(selectId(result) ? { id: selectId(result) } : {}),
        })),
      ]
    : [listTag];

  return result;
}

export function invalidatesTags(tagType, ...ids) {
  const result = ids.length
    ? [...ids.map((id) => ({ type: tagType, id }))]
    : [{ type: tagType, id: "LIST" }];

  return result;
}
